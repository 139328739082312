@font-face {
  font-family: monotype-Corsiva;
  src: url('../../../public/Monotype\ Corsiva.ttf');
}

@font-face {
  font-family: footlight;
  src: url('../../../public/FootlightMTProLight.otf');
}

@font-face {
  font-family: maiandra;
  src: url('../../../public/MAIAN.TTF');
}

@font-face {
  font-family: calibri;
  src: url('../../../public/Calibri\ Regular.ttf');
}

.foot {
  font-family: 'footlight';
}

@media screen and (max-width: 700px) {
  #asuhygf .nav-link {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
}

.vipar {
  font-family: 'monotype-Corsiva';
  color: rgb(153, 208, 80) !important;
}

.viparblack {
  font-family: 'monotype-Corsiva';
  color: 'black' !important;
}

.mono {
  font-family: 'monotype-Corsiva';
}

.maiandra {
  font-family: 'maiandra';
}

.calibri {
  font-family: 'calibri';
}

.cardSuper {
  min-height: 110px;
  border-radius: 20px;
}

.cardoper {
  background-color: #012060;
  color: white;
  border-radius: 25px;
  border: 1px solid rgb(255, 255, 255);
  /* box-shadow: 1px 1px 1px 1px #cccaca; */
  /* height: 360px; */
}

.link {
  color: white;
  text-decoration: none;
}

.text-justify {
  text-align: justify;
}

.transform {
  text-transform: none;
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 280px) and (max-device-width: 750px) and (orientation: landscape) {
  /* Styles */
  .adddss {
    margin-top: 35px !important;
  }
}

.boximage {
  width: 220px;
  height: 220px;
}

.newviparhomecardfont {
  font-size: 16px;
  text-transform: none;
}

.vaiparmonolobbt {
  font-size: 32px;
  color: #99d050;
  font-family: 'monotype-Corsiva';
}

.vaiparmonolobbttitle {
  font-size: 20px;
  font-family: maiandra;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .boximage {
    width: 150px;
    height: 150px;
  }
  .newviparhomecardfont {
    font-size: 12px;
    text-transform: none;
  }
  .vaiparmonolobbt {
    font-size: 25px;
    color: #99d050;
    font-family: 'monotype-Corsiva';
  }
  .vaiparmonolobbttitle {
    font-size: 17px;
    font-family: maiandra;
  }
}

@media screen and (max-width: 382px) {
  .nmm {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 340px) {
  .nmm {
    font-size: 14px !important;
  }
}

.onefont {
  font-size: 58px;
  display: inline-block;
}

.ssvc {
  font-size: 12px !important;
}

.cntttr {
  font-size: 8px !important;
}

.bld {
  font-weight: 600 !important;
}

.fntvv {
  font-size: 20px !important;
}

.fntsp {
  font-size: 30px !important;
}
.fntspm {
  font-family: 'monotype-Corsiva';
  font-size: 24px !important;
}
@media screen and (min-width: 768px) {
  .fntspm {
    font-size: 30px !important;
  }
}
.loko {
  position: absolute;
  right: 10px;
  color: white;
  top: 10px;
}

.ftcc {
  font-family: calibri !important;
  color: gray;
  /* font-weight: 900 !important; */
  font-size: 15px !important;
}

.szz {
  width: 150px;
  margin-right: 40px;
}

@media screen and (min-width: 576px) and (max-width: 1200px) {
  .szz {
    width: 190px !important;
  }
}
@media screen and (min-width: 1200px) {
  .szz {
    width: 190px !important;
  }
}

/* .blinkkk {
  animation: blink 0.3s step-start infinite;
}

@keyframes blinkkk {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

.blinkkk {
  animation: blinker 0.7s linear infinite;
}

@keyframes blinkkk {
  50% {
    opacity: 0;
  }
}

.blinkkkkk {
  animation: blinker 0.7s linear infinite;
}

@keyframes blinkkkkk {
  50% {
    opacity: 0;
  }
}

/* scroll button */

.scroll-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  display: block;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 1;
}

.scroll-button.hidden {
  opacity: 0;
  pointer-events: none;
}

.mgd {
  font-family: 'Maiandra' !important;
}

.prochure {
  font-family: 'Maiandra' !important;
  color: #5d334e;
}

.verticals {
  font-family: 'Maiandra' !important;
}

.hfont {
  font-size: 20px !important;
}
