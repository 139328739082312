.fntt{
    font-weight: 500 !important;
    font-size: calc(1.275rem + 0.3vw) !important;
    font-family: footlight !important;
  }



.fntt:hover{
    color:#0d6efd !important; 
    
}

@media screen and (max-width:765px) {
  .cardforproduct {
    max-width: 400px;
  }
}

@media screen and (max-width:550px) {
  .cardforproduct {
    max-width: 380px;
  }
}

@media screen and (max-width:450px) {
  .cardforproduct {
    max-width: 300px;
  }
}