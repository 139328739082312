@font-face {
    font-family: footlight;
    src: url('../../../../public/FootlightMTProLight.otf');
}
.dropbtn {
    display: inline;
   padding: 0px;
   background-color: rgba(255, 255, 255, 0);
    color: black;
   
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  .dropbtn:hover, .dropbtn:focus {
    border: none;
    outline: none;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown a:hover {background-color: #ddd;}
  
  .show {display: block;}



  .btnn:hover{
    background-color:black ;
    color: white;
  }