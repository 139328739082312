:root {
  --light: #f1f8ff;
  --dark: #0f172b;
  --hover: #99d056;
  --groceryTheam: #012060;
  --groceryTheamSec: #f65005;
}

.nav-link {
  color: var(--navLink) !important;
}

.nav-linkKK {
  color: var(--navLink) !important;
}

.mt-n4 {
  margintop: -1.5rem !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}

.my-6 {
  margintop: 6rem;
  margin-bottom: 6rem;
}

.py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.bg-icon {
  /* background: url(./img/bg-icon.png) center center repeat; */
  background-size: contain;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: 0.5s;
}

.btn.btn-groceryTheam,
.btn.btn-secondary,
.btn.btn-outline-groceryTheam:hover,
.btn.btn-outline-secondary:hover {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/*** Navbar ***/
.fixed-top {
  transition: 0.5s;
}

.top-bar {
  height: 45px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.navbar .navbar-nav .nav-link {
  /* padding: 0px 25px; */
  padding-right: 20px !important;
  padding-left: 20px !important;
  color: #555555;
  font-weight: 500;
  outline: none;
}

.navbar .navbar-nav .nav-linkKK {
  /* padding: 0px 25px; */
  padding-right: 12px !important;
  padding-left: 12px !important;
  color: #555555;
  text-decoration: none !important;
  font-weight: 500;
  outline: none;
}

.aneesddd a {
  font-size: 14px !important;
  text-decoration: none !important;
}

.ss {
  font-size: 15px !important;
}

.ssviparc {
  font-size: 15px !important;
}

.sscomfortfont {
  font-size: 15px !important;
  padding: 10px 0;
}

@media screen and (max-width: 365px) {
  .aneesddd a {
    font-size: 13px !important;
  }
  .navbar .navbar-nav .nav-linkKK {
    /* padding: 0px 25px; */
    padding-right: 10px !important;
    padding-left: 10px !important;
    color: #555555;
    text-decoration: none !important;
    font-weight: 500;
    outline: none;
  }
}

@media screen and (max-width: 340px) {
  .aneesddd a {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 800px) {
  .aneesddd a {
    font-size: 15px !important;
  }
}
@media screen and (min-width: 800px) {
  .aneesddd a {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 450px) {
  .fon {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 390px) {
  .logoVipar {
    width: 58px;
    height: 58px;
  }
  .fon {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 380px) {
  .nav-link {
    font-size: 15px !important;
  }
  .ss {
    font-size: 12px !important;
  }
  .ssviparc {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 370px) {
  .fon {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 350px) {
  .nav-link {
    font-size: 14px !important;
  }
  .ss {
    font-size: 10px !important;
  }
  .ssviparc {
    font-size: 12px !important;
  }
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: var(--hover);
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav {
    margintop: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    background: #ffffff;
  }

  .navbar .navbar-nav .nav-link {
    padding: 10px 0;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    visibility: hidden;
    top: 100%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
    transition: 0.5s;
    opacity: 0;
  }

  .navbar .nav-item:hover .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}

/*** Header ***/
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  textalign: start;
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
  background-color: var(--groceryTheam);
  border: 10px solid var(--groceryTheam);
  border-radius: 3rem;
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
    position: relative;
    min-height: 450px;
  }

  #header-carousel .carousel-item img {
    /* position: absolute; */
    width: 100%;
    height: 450px;
    object-fit: cover;
  }
}

.page-header {
  padding-top: 12rem;
  padding-bottom: 6rem;
  /* background: url(../../public/img/carousel-1.jpg) top right no-repeat; */
  background-size: cover;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #999999;
}

/*** Section Header ***/
.section-header {
  position: relative;
  padding-top: 25px;
}

.section-header::before {
  position: absolute;
  content: '';
  width: 60px;
  height: 2px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--groceryTheam);
}

.section-header::after {
  position: absolute;
  content: '';
  width: 90px;
  height: 2px;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--groceryTheamSec);
}

.section-header.text-start::before,
.section-header.text-start::after {
  left: 0;
  transform: translateX(0);
}

/*** About ***/
.about-img img {
  position: relative;
  z-index: 2;
}

.about-img::before {
  position: absolute;
  content: '';
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background-image: -webkit-repeating-radial-gradient(
    #ffffff,
    #eeeeee 5px,
    transparent 5px,
    transparent 10px
  );
  background-image: -moz-repeating-radial-gradient(
    #ffffff,
    #eeeeee 5px,
    transparent 5px,
    transparent 10px
  );
  background-image: -ms-repeating-radial-gradient(
    #ffffff,
    #eeeeee 5px,
    transparent 5px,
    transparent 10px
  );
  background-image: -o-repeating-radial-gradient(
    #ffffff,
    #eeeeee 5px,
    transparent 5px,
    transparent 10px
  );
  background-image: repeating-radial-gradient(
    #ffffff,
    #eeeeee 5px,
    transparent 5px,
    transparent 10px
  );
  background-size: 20px 20px;
  transform: skew(20deg);
  z-index: 1;
}

/*** Product ***/
.nav-pills .nav-item .btn {
  color: var(--dark);
}

.nav-pills .nav-item .btn:hover,
.nav-pills .nav-item .btn.active {
  color: #ffffff;
}

.product-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.07);
}

.product-item img {
  transition: 0.5s;
}

.product-item:hover img {
  transform: scale(1.1);
}

.product-item small a:hover {
  color: var(--groceryTheam) !important;
}

/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item img {
  width: 60px;
  height: 60px;
}

.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item .testimonial-item * {
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  background: var(--groceryTheam) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item * {
  color: #ffffff !important;
}

.testimonial-carousel .owl-item.center .testimonial-item i {
  color: var(--groceryTheamSec) !important;
}

.testimonial-carousel .owl-nav {
  margintop: 30px;
  display: flex;
  justify-content: center;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  margin: 0 12px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  border: 2px solid var(--groceryTheam);
  border-radius: 50px;
  font-size: 18px;
  transition: 0.5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  color: #ffffff;
  background: var(--groceryTheam);
}

/*** Footer ***/
.footer {
  color: #999999;
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  textalign: left;
  color: #999999;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #999999;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--light);
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .copyright a:hover {
  color: var(--groceryTheam);
}

/* Sucess Modal Start */

#success_tic_modal_success .page-body {
  max-width: 300px;
  background-color: #ffffff;
  margin: 10% auto;
}
#success_tic_modal_success .page-body .head {
  textalign: center;
}
/* #success_tic_modal_success .tic{
    font-size:186px;
  } */
#success_tic_modal_success .close {
  opacity: 1;
  position: absolute;
  right: 0px;
  font-size: 30px;
  padding: 3px 15px;
  margin-bottom: 10px;
}
#success_tic_modal_success .checkmark-circle {
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.checkmark-circle .background {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: var(--groceryTheam);
  position: absolute;
}
#success_tic_modal_success .checkmark-circle .checkmark {
  border-radius: 5px;
}
#success_tic_modal_success .checkmark-circle .checkmark.draw:after {
  -webkit-animation-delay: 300ms;
  -moz-animation-delay: 300ms;
  animation-delay: 300ms;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-name: checkmark;
  -moz-animation-name: checkmark;
  animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -o-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
#success_tic_modal_success .checkmark-circle .checkmark:after {
  opacity: 1;
  height: 30px;
  width: 30.5px;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  border-right: 15px solid #fff;
  border-top: 15px solid #fff;
  border-radius: 2.5px !important;
  content: '';
  left: 13px;
  top: 62px;
  position: absolute;
}

@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
@-moz-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}

/* Sucess Modal End */

/* Login Modal Start */

#loginModal .modal-dialog .modal-content {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  border: none;
}
#loginModal .modal-dialog .modal-header {
  border-bottom: none;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
}

#loginModal .modal-dialog .modal-header .modal-title {
  font-size: 17px;
  font-weight: 300;
  color: #3e3947;
  margin-bottom: 0;
  margintop: 10px;
  line-height: 1.5;
}

#loginModal .modal-dialog .modal-body {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
  color: #99d056;
}

#loginModal .modal .modal-header .close i {
  font-size: 16px;
  color: #999;
}

#loginModal .modal-dialog .modal-footer {
  border-top: none;
  padding: 24px !important;
}

#loginModal .modal-dialog .modal-body + .modal-footer {
  padding-top: 0 !important;
}
#loginModal .modal-dialog .modal-footer button {
  margin: 0 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  width: auto;
}

#loginModal .modal-login {
  /* max-width: 360px !important; */
  margintop: 130px;
}

#loginModal .modal-dialog .modal-content .card-signup .modal-header {
  padding-top: 0;
}

#loginModal .modal-login .modal-header .card-header {
  width: 100%;
}

#loginModal .modal-login .modal-header .close {
  color: #fff;
  top: -25px;
  right: 50px;
  text-shadow: none;
  position: absolute;
}

#loginModal .modal-login .modal-header .close i {
  color: #fff;
}

#loginModal .modal-login .modal-footer {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
#loginModal .modal-login .modal-header .card-header .social-line {
  margintop: 1rem;
  textalign: center;
  padding: 0;
}

#loginModal .modal-login .modal-header .card-header .social-line .btn {
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
}

#loginModal .modal-login .modal-body {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

#loginModal .modal-login .modal-body .form .description {
  padding-top: 15px;
  margin-bottom: -10px;
  color: #999;
}

#loginModal .card .card-title {
  color: #fff;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: Roboto Slab, Times New Roman, serif;
  margintop: 10px;
}

#loginModal .card .card-header {
  border-radius: 3px !important;
  padding: 1rem 15px;
  margin-left: 15px;
  margin-right: 15px;
  margintop: -30px;
  border: 0;
}
#loginModal .card .card-header-primary {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px var(--groceryTheam);
  background: linear-gradient(60deg, #012060, var(--groceryTheam));
}

#loginModal .card-plain .card-body {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

#loginModal .card .card-body {
  padding: 15px;
}

#loginModal .card form {
  margin: 0;
}

#loginModal .form-group {
  margin-bottom: 17px !important;
  position: relative;
}

#loginModal .bmd-form-group {
  position: relative;
  padding-top: 27px !important;
}

#loginModal .input-group-prepend {
  margin-right: -1px;
}

#loginModal .input-group .input-group-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  background-color: transparent;
  border-color: transparent;
}

#loginModal .form-control::placeholder {
  color: #aaaaaa !important;
  font-size: 14px !important;
}
/* 
#loginModal .form-control,
.is-focused .form-control {
  background-image: linear-gradient(
      0deg,
      var(--groceryTheam) 2px,
      rgba(156, 39, 176, 0) 0
    ),
    linear-gradient(0deg, #d2d2d2 1px, hsla(0, 0%, 82%, 0) 0) !important;
} */

#loginModal .form-control:focus {
  box-shadow: none !important;
}

#loginModal .modal-dialog .modal-body {
  padding: 24px 24px 16px;
}

#loginModal .modal-dialog .modal-footer button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}
#loginModal .modal-notice .instruction {
  margin-bottom: 25px;
}

#loginModal .modal-notice .picture {
  height: 87px !important;
  width: 131px !important;
}

#loginModal img.rounded {
  border-radius: 6px !important;
}

#loginModal .img-fluid {
  width: 100% !important;
  height: 100% !important;
}

#loginModal strong {
  font-weight: 400 !important;
}

#loginModal a {
  color: #3e3947 !important;
  text-decoration: none;
}

#loginModal a:hover {
  color: var(--hover) !important;
  text-decoration: underline !important;
}

#loginModal .close-button-modal {
  color: #ffffff !important;
}

/* Login Modal End */

/* register Modal Start */

#RegisterModal .modal-dialog .modal-content {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  border: none;
}
#RegisterModal .modal-dialog .modal-header {
  border-bottom: none;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
}

#RegisterModal .modal-dialog .modal-header .modal-title {
  font-size: 17px;
  font-weight: 300;
  color: #3e3947;
  margin-bottom: 0;
  margintop: 10px;
  line-height: 1.5;
}

#RegisterModal .modal-dialog .modal-body {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
  color: #99d056;
}

#RegisterModal .modal .modal-header .close i {
  font-size: 16px;
  color: #999;
}

#RegisterModal .modal-dialog .modal-footer {
  border-top: none;
  padding: 24px !important;
}

#RegisterModal .modal-dialog .modal-body + .modal-footer {
  padding-top: 0 !important;
}
#RegisterModal .modal-dialog .modal-footer button {
  margin: 0 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  width: auto;
}

#RegisterModal .modal-login {
  /* max-width: 360px !important; */
  margintop: 130px;
}

#RegisterModal .modal-dialog .modal-content .card-signup .modal-header {
  padding-top: 0;
}

#RegisterModal .modal-login .modal-header .card-header {
  width: 100%;
}

#RegisterModal .modal-login .modal-header .close {
  color: #fff;
  top: -25px;
  right: 50px;
  text-shadow: none;
  position: absolute;
}

#RegisterModal .modal-login .modal-header .close i {
  color: #fff;
}

#RegisterModal .modal-login .modal-footer {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
#RegisterModal .modal-login .modal-header .card-header .social-line {
  margintop: 1rem;
  textalign: center;
  padding: 0;
}

#RegisterModal .modal-login .modal-header .card-header .social-line .btn {
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
}

#RegisterModal .modal-login .modal-body {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

#RegisterModal .modal-login .modal-body .form .description {
  padding-top: 15px;
  margin-bottom: -10px;
  color: #999;
}

#RegisterModal .card .card-title {
  color: #fff;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: Roboto Slab, Times New Roman, serif;
  margintop: 10px;
}

#RegisterModal .card .card-header {
  border-radius: 3px !important;
  padding: 1rem 15px;
  margin-left: 15px;
  margin-right: 15px;
  margintop: -30px;
  border: 0;
}
#RegisterModal .card .card-header-primary {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px var(--groceryTheam);
  background: linear-gradient(60deg, #012060, var(--groceryTheam));
}

#RegisterModal .card-plain .card-body {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

#RegisterModal .card .card-body {
  padding: 15px;
}

#RegisterModal .card form {
  margin: 0;
}

#RegisterModal .form-group {
  margin-bottom: 17px !important;
  position: relative;
}

#RegisterModal .bmd-form-group {
  position: relative;
  padding-top: 27px !important;
}

#RegisterModal .input-group-prepend {
  margin-right: -1px;
}

#RegisterModal .input-group .input-group-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  background-color: transparent;
  border-color: transparent;
}

#RegisterModal .form-control::placeholder {
  color: #aaaaaa !important;
  font-size: 14px !important;
}

/* #RegisterModal .form-control,
.is-focused .form-control {
  background-image: linear-gradient(
      0deg,
      var(--groceryTheam) 2px,
      rgba(156, 39, 176, 0) 0
    ),
    linear-gradient(0deg, #d2d2d2 1px, hsla(0, 0%, 82%, 0) 0) !important;
} */

#RegisterModal .form-control:focus {
  box-shadow: none !important;
}

#RegisterModal .modal-dialog .modal-body {
  padding: 24px 24px 16px;
}

#RegisterModal .modal-dialog .modal-footer button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}
#RegisterModal .modal-notice .instruction {
  margin-bottom: 25px;
}

#RegisterModal .modal-notice .picture {
  height: 87px !important;
  width: 131px !important;
}

#RegisterModal img.rounded {
  border-radius: 6px !important;
}

#RegisterModal .img-fluid {
  width: 100% !important;
  height: 100% !important;
}

#RegisterModal strong {
  font-weight: 400 !important;
}

#RegisterModal a {
  color: #3e3947 !important;
  text-decoration: none;
}

#RegisterModal a:hover {
  color: var(--hover) !important;
  text-decoration: underline !important;
}

#RegisterModal .close-button-modal {
  color: #ffffff !important;
}

/* Register Modal End */

/* OTP modal start */

#otpModal .height-100 {
  height: 100vh;
}

#otpModal h6 {
  color: var(--groceryTheam);
  font-size: 20px;
}

#otpModal .inputs input {
  width: 40px;
  height: 40px;
}

#otpModal input[type='number']::-webkit-inner-spin-button,
#otpModal input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#otpModal .card-2 {
  background-color: #fff;
  padding: 10px;
  width: 350px;
  height: 100px;
  bottom: -50px;
  left: 20px;
  position: absolute;
  border-radius: 5px;
}

#otpModal .card-2 .content {
  margintop: 50px;
}

#otpModal .card-2 .content a {
  color: var(--groceryTheam);
}

#otpModal .form-control:focus {
  box-shadow: none;
  border: 2px solid var(--groceryTheam);
}

#otpModal .validate {
  border-radius: 20px;
  height: 40px;
  background-color: var(--groceryTheam);
  border: 1px solid var(--groceryTheam);
  width: 140px;
}
.hhchange {
  background-color: #012060 !important;
  color: #f1f8ff !important;
}
.hhchange:hover {
  color: #99d056 !important;
}

#mjbmjgbjgmmmasasassa {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

#nsknfkjdsnfksjn ul li {
  display: inline;
  padding-right: 18px;
  padding-left: 18px;
}

#nsknfkjdsnfksjn li a {
  display: inline !important;
}
/* OTP modal Ends */
/* .sp{
   margin-top: 10px !important;
} */

.aaaaaaa {
  font-family: 'monotype-Corsiva';

  @media only screen and (max-width: 780) {
    .zx {
      font-size: 20px;
    }
  }
  color: black !important;
}

.adds {
  font-family: sans-serif !important;
}

@media only screen and (min-width: 389px) {
  .asddd {
    height: 26px !important;
  }
}

.prodrop:has(.show) .cv {
  font-weight: 900 !important;
}

@media only screen and (max-width: 510px) {
  .xc {
    font-size: 21px !important;
  }
}

@media only screen and (max-width: 780px) {
  .zx {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 750px) {
  .zx {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 662px) {
  .zx {
    font-size: 12 px !important;
  }
  .xz {
    margin-left: -57px;
  }
}
@media only screen and (max-width: 550px) {
  .xz {
    margin-left: -148px;
  }
}

/* registermodallo Modal Start */

#RegisterModallo .modal-dialog .modal-content {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  border: none;
}
#RegisterModallo .modal-dialog .modal-header {
  border-bottom: none;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
}

#RegisterModallo .modal-dialog .modal-header .modal-title {
  font-size: 17px;
  font-weight: 300;
  color: #3e3947;
  margin-bottom: 0;
  margintop: 10px;
  line-height: 1.5;
}

#RegisterModallo .modal-dialog .modal-body {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
  color: #99d056;
}

#RegisterModallo .modal .modal-header .close i {
  font-size: 16px;
  color: #999;
}

#RegisterModallo .modal-dialog .modal-footer {
  border-top: none;
  padding: 24px !important;
}

#RegisterModallo .modal-dialog .modal-body + .modal-footer {
  padding-top: 0 !important;
}
#RegisterModallo .modal-dialog .modal-footer button {
  margin: 0 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  width: auto;
}

#RegisterModallo .modal-login {
  /* max-width: 360px !important; */
  margintop: 130px;
}

#RegisterModallo .modal-dialog .modal-content .card-signup .modal-header {
  padding-top: 0;
}

#RegisterModallo .modal-login .modal-header .card-header {
  width: 100%;
}

#RegisterModallo .modal-login .modal-header .close {
  color: #fff;
  top: -25px;
  right: 50px;
  text-shadow: none;
  position: absolute;
}

#RegisterModallo .modal-login .modal-header .close i {
  color: #fff;
}

#RegisterModallo .modal-login .modal-footer {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
#RegisterModallo .modal-login .modal-header .card-header .social-line {
  margintop: 1rem;
  textalign: center;
  padding: 0;
}

#RegisterModallo .modal-login .modal-header .card-header .social-line .btn {
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
}

#RegisterModallo .modal-login .modal-body {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

#RegisterModallo .modal-login .modal-body .form .description {
  padding-top: 15px;
  margin-bottom: -10px;
  color: #999;
}

#RegisterModallo .card .card-title {
  color: #fff;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: Roboto Slab, Times New Roman, serif;
  margintop: 10px;
}

#RegisterModallo .card .card-header {
  border-radius: 3px !important;
  padding: 1rem 15px;
  margin-left: 15px;
  margin-right: 15px;
  margintop: -30px;
  border: 0;
}
#RegisterModallo .card .card-header-primary {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px var(--groceryTheam);
  background: linear-gradient(60deg, #012060, var(--groceryTheam));
}

#RegisterModallo .card-plain .card-body {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

#RegisterModallo .card .card-body {
  padding: 15px;
}

#RegisterModallo .card form {
  margin: 0;
}

#RegisterModallo .form-group {
  margin-bottom: 17px !important;
  position: relative;
}

#RegisterModallo .bmd-form-group {
  position: relative;
  padding-top: 27px !important;
}

#RegisterModallo .input-group-prepend {
  margin-right: -1px;
}

#RegisterModallo .input-group .input-group-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  background-color: transparent;
  border-color: transparent;
}

#RegisterModallo .form-control::placeholder {
  color: #aaaaaa !important;
  font-size: 14px !important;
}

#RegisterModallo .form-control:focus {
  box-shadow: none !important;
}

#RegisterModallo .modal-dialog .modal-body {
  padding: 24px 24px 16px;
}

#RegisterModallo .modal-dialog .modal-footer button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}
#RegisterModallo .modal-notice .instruction {
  margin-bottom: 25px;
}

#RegisterModallo .modal-notice .picture {
  height: 87px !important;
  width: 131px !important;
}

#RegisterModallo img.rounded {
  border-radius: 6px !important;
}

#RegisterModallo .img-fluid {
  width: 100% !important;
  height: 100% !important;
}

#RegisterModallo strong {
  font-weight: 400 !important;
}

#RegisterModallo a {
  color: #3e3947 !important;
  text-decoration: none;
}

#RegisterModallo a:hover {
  color: var(--hover) !important;
  text-decoration: underline !important;
}

#RegisterModallo .close-button-modal {
  color: #ffffff !important;
}

/* registermodallo Modal end */

.bv {
  height: calc(100vh - 230px);
}

@media only screen {
  .bv {
    height: calc(100vh - 280px);
  }
}

@media only screen and (max-width: 465px) {
  .nm {
    font-size: 15px;
  }
}
@media only screen and (max-width: 405px) {
  .nm {
    font-size: 13px;
  }
}
@media only screen and (max-width: 365px) {
  .nm {
    font-size: 10px;
  }
}

.ftte {
  font-size: 17px !important;
  font-weight: bold !important;
}

@media only screen and (max-width: 500px) {
  .xx {
    padding-left: 10px;
  }
  .xn {
    padding-left: 5px;
  }
}
@media only screen and (max-width: 430px) {
  .xx {
    padding-left: 10px;
  }
  .xn {
    padding-left: 5px;
  }
}

@media only screen and (max-width: 370px) {
  .ml {
    font-size: 15px !important;
  }
}

.lm {
  padding-left: 70px !important;
}
@media only screen and (max-width: 465px) {
  .lm {
    padding-left: 50px !important;
  }
}
@media only screen and (max-width: 400px) {
  .lm {
    padding-left: 30px !important;
  }
}
@media only screen and (max-width: 350px) {
  .lm {
    padding-left: 0px !important;
  }
}

.zz {
  font-size: 20px !important;
  font-weight: 900 !important;
}
.ssvc {
  padding-top: 22px !important;
}

@media only screen and (max-width: 401px) {
  .ssvc {
    padding-top: 22px !important;
  }
}

@media only screen and (max-width: 382px) {
  .ssvc {
    padding-top: 22px !important;
  }
}
@media only screen and (max-width: 380px) {
  .ssvc {
    padding-top: 19px !important;
  }
}

/* .cbv {
  background:#fff !important;
  box-shadow:0 1px 0 rgba(0,0,0,.2)      !important;
  margin:2em auto  !important;

  padding:1em !important;
  position:relative !important;
  width:33% !important;
} */
/* lil box*/

/* .vb .vb_n {
position: absolute;
top: 0;
right: 0;
width: 2em;
height: 2em;
background-color:#888888
} */

/* .vb .vb_n .vb_n-triangle {
position: absolute;
width: 0;
height: 0;
border-style: solid;
border-width: 0 2em 2em 0;
border-color: transparent #f1f2f2 transparent transparent;
} */

.cnb {
  /* border: 1px solid; */
  /* padding: 10px; */
  /* box-shadow: 5px 10px #888888; */
}

.nmb {
  font-size: 18px !important;
}

@media only screen and (max-width: 400px) {
  .nmb {
    font-size: 18px !important;
  }
}

.bnm {
  font-size: 18px !important;
}
.bnmm {
  font-size: 16px !important;
}

#aasdasdadsasdasdasdasdasd {
  padding-left: 12px !important;
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'] {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="%23343a40" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2 5l6 6 6-6"/></svg>') !important;
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #212529 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border-color: #ced4da !important;
  border: 1px solid #ced4da !important;
  background-image: none !important;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size='1'],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size='1'] {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border-color: #ced4da !important;
  border: 1px solid #ced4da !important;
  background-image: none !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media only screen and (max-width: 495px) {
  .bbbb {
    width: 150px !important;
  }
  .nn {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 395px) {
  .bbbb {
    width: 150px !important;
  }
  .nn {
    font-size: 10px !important;
  }
}

.mm {
  font-family: calibri !important;
  color: black;
  /* font-weight: 900 !important; */
  font-size: 20px !important;
}
.mmm {
  font-family: calibri !important;
  color: black;
  /* font-weight: 900 !important; */
  font-size: 20px !important;
  display: block;
  text-align: center !important ;
}

@media only screen and (max-width: 495px) {
  .mm {
    font-family: calibri !important;
    color: black;
    /* font-weight: 900 !important; */
    font-size: 15px !important;
  }
  .mmm {
    font-family: calibri !important;
    color: black;
    /* font-weight: 900 !important; */
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 395px) {
  .mmm {
    font-family: calibri !important;
    color: black;
    /* font-weight: 900 !important; */
    font-size: 15px !important;
  }
  .mm {
    font-family: calibri !important;
    color: black;
    /* font-weight: 900 !important; */
    font-size: 12px !important;
    display: block;
  }
}

.mz {
  position: fixed;
  z-index: 999;
  bottom: 0px;
  right: 0px;
  /* top: 30px; */
}

.jodit-status-bar {
  display: none !important;
}

.jodit-icon_image {
  display: none !important;
}
