/********** Template CSS **********/
:root {
  --primary: #eb1616;
  --secondary: #191c24 !important;
  --light: rgb(255, 255, 255) !important;
  --dark: #000000 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  transition: 0.5s;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}

/*** Layout ***/
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  height: 100vh;
  overflow-y: auto;
  background: var(--secondary);
  transition: 0.5s;
  z-index: 999;
}

.content {
  margin-left: 250px;
  min-height: 100vh;
  background: var(--dark);
  transition: 0.5s;
}

@media (min-width: 992px) {
  .sidebar {
    margin-left: 0;
  }

  .sidebar.open {
    margin-left: -250px;
  }

  .content {
    width: calc(100% - 250px);
  }

  .content.open {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 991.98px) {
  .sidebar {
    margin-left: -250px;
  }

  .sidebar.open {
    margin-left: 0;
  }

  .content {
    width: 100%;
    margin-left: 0;
  }
}

/*** Navbar ***/
.sidebar .navbar .navbar-nav .nav-link {
  padding: 7px 20px;
  color: var(--light);
  font-weight: 500;
  border-left: 3px solid var(--secondary);
  border-radius: 0 30px 30px 0;
  outline: none;
}



.sidebar .navbar .navbar-nav .nav-link i {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}



.sidebar .navbar .dropdown-toggle::after {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  transition: 0.5s;
}

.sidebar .navbar .dropdown-toggle[aria-expanded='true']::after {
  transform: rotate(-180deg);
}

.sidebar .navbar .dropdown-item {
  padding-left: 25px;
  border-radius: 0 30px 30px 0;
  color: var(--light);
}

.sidebar .navbar .dropdown-item:hover,
.sidebar .navbar .dropdown-item.active {
  background: var(--dark);
}

.content .navbar .navbar-nav .nav-link {
  margin-left: 25px;
  padding: 12px 0;
  color: var(--light);
  outline: none;
}

.content .navbar .navbar-nav .nav-link:hover,
.content .navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.content .navbar .sidebar-toggler,
.content .navbar .navbar-nav .nav-link i {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 40px;
}

.content .navbar .dropdown-item {
  color: var(--light);
}

.content .navbar .dropdown-item:hover,
.content .navbar .dropdown-item.active {
  background: var(--dark);
}

.content .navbar .dropdown-toggle::after {
  margin-left: 6px;
  vertical-align: middle;
  border: none;
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  transition: 0.5s;
}

.content .navbar .dropdown-toggle[aria-expanded='true']::after {
  transform: rotate(-180deg);
}

@media (max-width: 575.98px) {
  .content .navbar .navbar-nav .nav-link {
    margin-left: 15px;
  }
}

/*** Date Picker ***/
.bootstrap-datetimepicker-widget.bottom {
  top: auto !important;
}

.bootstrap-datetimepicker-widget .table * {
  border-bottom-width: 0px;
}

.bootstrap-datetimepicker-widget .table th {
  font-weight: 500;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 10px;
  border-radius: 2px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background: var(--primary);
}

.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: var(--primary);
}

/*** Testimonial ***/
.progress .progress-bar {
  width: 0px;
  transition: 2s;
}

/*** Testimonial ***/
.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 5px solid var(--primary);
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--dark);
  border-color: var(--primary);
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* https://themewagon.com/themes/free-bootstrap-5-html5-organic-food-website-template-foody/ */

/* https://themewagon.com/themes/restoran-free-responsive-bootstrap-5-restaurant-website-template/ */

/********** Template CSS **********/
:root {
  --light: #f1f8ff;
  --dark: #0f172b;
  --navLink: #000000;
  --groceryTheam: #3cb815;
  --groceryTheamSec: #f65005;
}

.text-groceryTheam {
  color: var(--groceryTheam);
}

.text-groceryTheamSec {
  color: var(--groceryTheamSec);
}

.bg-groceryTheam {
  --bs-bg-opacity: 1;
  background-color: var(--groceryTheam);
}

.btn-groceryTheam {
  color: #ffffff;
  background-color: var(--groceryTheam) !important;
  border-color: var(--groceryTheam) !important;
}

.btn-groceryTheamSecondary {
  color: #ffffff;
  background-color: var(--groceryTheamSec) !important;
  border-color: var(--groceryTheamSec) !important;
}

.btn-outline-groceryTheam {
  color: var(--groceryTheam) !important;
  border-color: var(--groceryTheam) !important;
  border-width: 2px !important;
  font-weight: 500 !important;
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw) !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
  color: #111;
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw) !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
}
/* 
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-bottom: 0.5rem;
  font-family: 'Lora', serif !important;
  font-weight: 600;
  line-height: 1.2;
  color: #111;
} */

.border-start11 {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--groceryTheam) !important;
}

.ff-secondary {
  font-family: 'Pacifico', cursive;
}

.fw-medium {
  font-weight: 600 !important;
}

.fw-semi-bold {
  font-weight: 700 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.5s;
}

.btn.btn-groceryTheam,
.btn.btn-groceryTheamSecondary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 2px;
}

/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
  position: relative;
  margin-left: 25px;
  padding: 35px 0;
  font-size: 15px;
  color: var(--light) !important;
  text-transform: uppercase;
  font-weight: 500;
  outline: none;
  transition: 0.5s;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
  padding: 20px 0;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: var(--groceryTheam) !important;
}

.navbar-dark .navbar-brand img {
  max-height: 60px;
  transition: 0.5s;
}

.sticky-top.navbar-dark .navbar-brand img {
  max-height: 45px;
}

@media (max-width: 991.98px) {
  .sticky-top.navbar-dark {
    position: relative;
  }

  .navbar-dark .navbar-collapse {
    margintop: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .navbar-dark .navbar-nav .nav-link,
  .sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 10px 0;
    margin-left: 0;
  }

  .navbar-dark .navbar-brand img {
    max-height: 45px;
  }
}

@media (min-width: 992px) {
  .navbar-dark {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background: transparent !important;
  }

  .sticky-top.navbar-dark {
    position: fixed;
    background: var(--dark) !important;
  }
}

/*** Hero Header ***/
.hero-header {
  /* background: linear-gradient(rgba(15, 23, 43, 0.9), rgba(15, 23, 43, 0.9)),
    url(vipar.in/img/bg-hero.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-header img {
  animation: imgRotate 50s linear infinite;
}

@keyframes imgRotate {
  100% {
    transform: rotate(360deg);
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.5);
}

/*** Section Title ***/
.section-title {
  position: relative;
  display: inline-block;
}

.section-title::before {
  position: absolute;
  content: '';
  width: 45px;
  height: 2px;
  top: 50%;
  left: -55px;
  margintop: -1px;
  background: var(--groceryTheam);
}

.section-title::after {
  position: absolute;
  content: '';
  width: 45px;
  height: 2px;
  top: 50%;
  right: -55px;
  margintop: -1px;
  background: var(--groceryTheam);
}

.section-title.text-start::before,
.section-title.text-end::after {
  display: none;
}

/*** Service ***/
.service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
}

.service-item:hover {
  background: var(--groceryTheam);
}

.service-item * {
  transition: 0.5s;
}

.service-item:hover * {
  color: var(--light) !important;
}

/*** Food Menu ***/
.nav-pills .nav-item .active {
  border-bottom: 2px solid var(--groceryTheam);
}

/*** Team ***/
.team-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  height: calc(100% - 38px);
  transition: 0.5s;
}

.team-item img {
  transition: 0.5s;
}

.team-item:hover img {
  transform: scale(1.1);
}

.team-item:hover {
  height: 100%;
}

.team-item .btn {
  border-radius: 38px 38px 0 0;
}

/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item.center .testimonial-item * {
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  background: var(--groceryTheam) !important;
  border-color: var(--groceryTheam) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item * {
  color: var(--light) !important;
}

.testimonial-carousel .owl-dots {
  margintop: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #cccccc;
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--groceryTheam);
  border-color: var(--groceryTheam);
}

/*** Footer ***/
.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid #ffffff;
  border-radius: 35px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--groceryTheam);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  textalign: left;
  color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.bg-custom-light {
  background-color: #f1f8ff;
}

@font-face {
  font-family: FootlightMTProLight;
  src: url('../../public/FootlightMTProLight.otf');
}

* {
  font-family: FootlightMTProLight;
}

.vipar-bg-dark {
  background-color: var(--dark) !important;
  color: var(--light) !important;
}

.ptag > p {
  margin: 0 !important;
}

/* p,
ul,
span {
  text-align: justify;
} */

#viparHome p {
  text-align: unset;
}
#viparHome span {
  text-align: unset;
}

@media screen and (orientation: landscape) {
  .mobileclass {
    display: none;
  }

  /* #Tommy{
    display: block;
  } */
}

#Tommy {
  display: none;
}

@media only screen and (pointer: coarse) and (min-width: 320px) and (max-width: 1002px) and (orientation: landscape) {
  #Tommy {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: var(--blue);
  }
}

.btnoutt {
  border: 1px solid;
  background-color: white;
  color: #012060;
  text-align: center;
  padding: 4px 12px;
  border-radius: 43px;

  cursor: pointer;
}

.btnouttt {
  border: 1px solid;
  background-color: white;
  color: #012060;
  text-align: center;
  padding: 4px 12px;
  border-radius: 43px;

  cursor: pointer;
}



