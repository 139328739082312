
* {
  font-family: 'FootlightMTLight';
}
* body,
ul,
li,
p,
a,
label,
input,
div {
  font-family: 'FootlightMTLight';
  font-size: 18px !important;
  font-weight: 300 !important;
}
.h1 {
  font-family: 'FootlightMTLight';
  font-size: 48px !important;
  font-weight: 200 !important;
}
.h2 {
  font-family: 'FootlightMTLight';
  font-size: 30px !important;
  font-weight: 300;
}
.h3 {
  font-family: 'FootlightMTLight';
  font-size: 20px !important;
}
.h4 {
  font-family: 'FootlightMTLight';
  font-size: 19px !important;
}
.h5 {
  font-family: 'FootlightMTLight';
  font-size: 15px !important;
}
/* General */
.logo {
  font-weight: 500 !important;
}
.text-warning {
  color: #ede861 !important;
}
.text-muted {
  color: #bcbcbc !important;
}
.text-success {
  color: #59ab6e !important;
}
.text-vio {
  color: #012060 !important;
}
.text-light {
  color: #cfd6e1 !important;
}
.bg-dark {
  background-color: #212934 !important;
}
.bg-light {
  background-color: #e9eef5 !important;
}
.bg-black {
  background-color: #1d242d !important;
}
.bg-success {
  background-color: #59ab6e !important;
}
.bg-vio {
  background-color: #012060 !important;
}
.btn-vio {
  background-color: #012060 !important;

  color: white;
}
.btn-vio:hover {
  color: white;
}
.btn-vio:active {
  background-color: gray !important;

  color: white;
}
.btn-vio:focus {
  background-color: gray !important;

  color: white;
}
.pagination .page-link:hover {
  color: #000;
}
.pagination .page-link:hover,
.pagination .page-link.active {
  background-color: #69bb7e;
  color: #fff;
}
/* Nav */
#templatemo_nav_top {
  min-height: 40px;
}
#templatemo_nav_top * {
  font-size: 0.9em !important;
}
#templatemo_main_nav .a {
  color: #212934;
}
#templatemo_main_nav .aa {
  color: green;
}
#templatemo_main_nav a:hover {
  color: #69bb7e;
}
#templatemo_main_nav .navbar .nav-icon {
  margin-right: 20px;
}

/* Hero Carousel */
#template-mo-zay-hero-carousel {
  background: #efefef !important;
}
/* Accordion */
.templatemo-accordion a {
  color: #000;
}
.templatemo-accordion a:hover {
  color: #333d4a;
}
/* Shop */
.shop-top-menu a:hover {
  color: #69bb7e !important;
}
/* Product */
.product-wap {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
}
.product-wap .product-color-dot.color-dot-red {
  background: #f71515;
}
.product-wap .product-color-dot.color-dot-blue {
  background: #6db4fe;
}
.product-wap .product-color-dot.color-dot-black {
  background: #000000;
}
.product-wap .product-color-dot.color-dot-light {
  background: #e0e0e0;
}
.product-wap .product-color-dot.color-dot-green {
  background: #0bff7e;
}
.card.product-wap .card .product-overlay {
  background: rgba(0, 0, 0, 0.2);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
.card.product-wap:hover .card .product-overlay {
  visibility: visible;
  opacity: 1;
}
.card.product-wap a {
  color: #000;
}
#carousel-related-product .slick-slide:focus {
  outline: none !important;
}
#carousel-related-product .slick-dots li button:before {
  font-size: 15px;
  margin-top: 20px;
}
/* Brand */
.brand-img {
  filter: grayscale(100%);
  opacity: 0.5;
  transition: 0.5s;
}
.brand-img:hover {
  filter: grayscale(0%);
  opacity: 1;
}
/* Carousel Hero */
#template-mo-zay-hero-carousel .carousel-indicators li {
  margin-top: -30px;
  background-color: #012060;
}
#template-mo-zay-hero-carousel .carousel-control-next i,
#template-mo-zay-hero-carousel .carousel-control-prev i {
  color: #012060 !important;
  font-size: 2.8em !important;
}
/* Carousel Brand */
.tempaltemo-carousel .h1 {
  font-size: 0.5em !important;
  color: #000 !important;
}
/* Services */
.services-icon-wap {
  transition: 0.3s;
}
.services-icon-wap:hover,
.services-icon-wap:hover i {
  color: #fff;
}
.services-icon-wap:hover {
  background: #012060;
}
/* Contact map */
.leaflet-control a,
.leaflet-control {
  font-size: 10px !important;
}
.form-control {
  border: 1px solid #e8e8e8;
}
/* Footer */
#tempaltemo_footer a {
  color: #dcdde1;
}
#tempaltemo_footer a:hover {
  color: #68bb7d;
}
#tempaltemo_footer ul.footer-link-list li {
  padding-top: 10px;
}
#tempaltemo_footer ul.footer-icons li {
  width: 2.6em;
  height: 2.6em;
  line-height: 2.6em;
}
#tempaltemo_footer ul.footer-icons li:hover {
  background-color: #cfd6e1;
  transition: 0.5s;
}
#tempaltemo_footer ul.footer-icons li:hover i {
  color: #212934;
  transition: 0.5s;
}
#tempaltemo_footer .border-light {
  border-color: #2d343f !important;
}
/*
// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap
*/
/* Small devices (landscape phones, 576px and up)*/
.product-wap .h3,
.product-wap li,
.product-wap i,
.product-wap p {
  font-size: 12px !important;
}
.product-wap .product-color-dot {
  width: 6px;
  height: 6px;
}

@media (min-width: 576px) {
  .tempaltemo-carousel .h1 {
    font-size: 1em !important;
  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  #templatemo_main_nav .navbar-nav {
    max-width: 450px;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  #templatemo_main_nav .navbar-nav {
    max-width: 550px;
  }
  #template-mo-zay-hero-carousel .carousel-item {
    min-height: 30rem !important;
  }
  .product-wap .h3,
  .product-wap li,
  .product-wap i,
  .product-wap p {
    font-size: 18px !important;
  }
  .product-wap .product-color-dot {
    width: 12px;
    height: 12px;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}
