.ul {
    list-style-type: none;
    margin: "10px";
    padding: 0;
    width: 200px;
    background-color:white;
   height: -webkit-fill-available;
  }
  
  .li  {
    display: block;
    color: #000;
    padding: 8px 16px;
    text-decoration: none;
  }
    
  .li:hover {
    background-color: #555;
    color: white;
  }