/*
Custom Css
*/

@font-face {
  font-family: 'Maiandra';
  src: url('../../../../public/MAIAN.TTF') format('truetype');

  /* font-family: 'gabriola';
    src: url('/assets/font/gabriola.ttf') format('truetype'); */
}
@font-face {
  font-family: 'FootlightMTLight';
  src: url('../../../../public/FootlightMTProLight.otf') format('truetype');
}
/* 
  * {
    font-family: 'FootlightMTLight';
  } */
.editname {
  font-family: 'FootlightMTLight';
  font-weight: 300;
  font-size: 30px !important;
  width: 100%;
  border: 0;
}
.toperrrr {
  margin-top: -100px;
}

.headingbottom {
  font-family: 'FootlightMTLight';
  font-weight: 300;
  font-size: 30px !important;
  width: 100%;
  border: 0;
}

.subheadingbottom {
  font-family: 'FootlightMTLight';
  font-weight: 300;
  font-size: 20px !important;
  width: 100%;
  border: 0;
}

.editprice {
  font-family: 'FootlightMTLight';
  font-size: 22px !important;
  border: 0;
}

.editdis {
  font-family: 'FootlightMTLight';
  font-size: 18px !important;
  border: 0;
  font-weight: 300;
  width: 100%;
}

/* .emptycartheight {
  height: 75vh;
} */

.emptycartheight {
  border: none;
}

.ruigwuitgosr {
  margin-top: 0px;
}

@media screen and (min-device-width: 600px) and (max-device-width: 900px) and (orientation: landscape) {
  .ruigwuitgosr {
    margin-top: 0px;
  }
}

.cartempty {
  margin-top: 160px;
}

.card-blue {
  background-color: #492bc4;
}

.blueicon {
  color: #14083f;
  font-size: 25px;
}

@media screen and (max-width: 390px) {
  .cardtexttlower {
    font-size: 12px !important;
  }
}
/* @media screen and (max-width:355px) {
  .cardtexttlower{
    font-size: 10px !important;
  }
} */

.blueicon2 {
  color: white;
  font-size: 45px;
  font-family: 'Maiandra';
}

.fgufhi {
  font-size: 25px;
}
/* .tgjg{
     
     text-size-adjust: 50px;
  } */

.subheadding {
  font-size: 23px;
  font-family: 'FootlightMTLight';
}
.linknew {
  text-decoration: none;
  color: black;
}

.p {
  /* font-family: 'Footlight+MT+Light'; */

  /* font-family: 'gabriola'; */
  font-size: 27px !important;
  font-weight: 300 !important;
}

.vipar {
  font-size: 45px;
  color: green;
}
.reg {
  color: green;
}
.viparfooter {
  font-size: 30px;
  color: #99d050;
}
.regfooter {
  color: green;
}

.cardheight {
  height: 150px;
}

.dubcbodyheight {
  height: 200px;
}

.modaltrans {
  background-color: #f8f9fa;
}

.discrpfvniv {
  font-size: 20px;
}
.pppp {
  font-family: 'FootlightMTLight';
  line-height: 2;
}

.abouttextcon {
  font-family: 'Maiandra';
  font-size: 45px;
  color: white;
}
.vipiargrrencolforabout {
  color: green;
  font-size: 70px;
}
.abouttextname2 {
  font-family: 'Maiandra';
  font-size: 33px;
  color: white;
}
.abouttextname {
  font-family: 'Maiandra';
  font-size: 25px;
  color: white;
}
.bgvipar {
  background-color: #012060;
}
.imagesize {
  /* margin-top: -100px; */
  height: 300px;
  width: 300px;
}
.pfont {
  font-family: 'Maiandra';
}

.sxgiugs {
  background-color: #0c0c0c;
}
.fggt {
  font-size: 32px;
  /* font-family: 'gabriola'; */
}

@media screen and (max-width: 540px) {
  .p {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 445px) {
  .p {
    font-size: 24px !important;
  }
  .fggt {
    font-size: 35px;
  }
}
@media screen and (max-width: 1200px) {
  .vipiargrrencolforabout {
    font-size: 45px;
  }
  .abouttextcon {
    font-size: 28px;
  }
  .blueicon2 {
    font-size: 28px;
  }
  .abouttextname {
    font-size: 15px;
  }
  .abouttextname2 {
    font-size: 21px;
  }
  .vipar {
    font-size: 28px;
  }
  .imagesize {
    /* margin-top: px; */
    height: 250px;
    width: 250px;
  }
  .toperrrr {
    margin-top: -60px;
  }
}
@media screen and (max-width: 992px) {
  .imagesize {
    height: 200px;
    width: 200px;
  }
}
@media screen and (max-width: 767px) {
  .imagesize {
    /* margin-top: 3px; */
    /* padding: 0; */
    height: 200px;
    width: 200px;
  }
}

.cardimgwidth {
  width: 1300px;
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .cardimgwidth {
    width: 522px;
  }
}
@media screen and (max-width: 577px) {
  .cardimgwidth {
    width: 450px;
  }
}

.linkcolor {
  text-decoration: green;
}

.blink_me {
  color: red;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.services-icon-wap {
  transition: 0.3s;
}
.services-icon-wap:hover,
.services-icon-wap:hover i {
  color: #fff;
}
.services-icon-wap:hover {
  background: #012060 !important;
}

.fs {
  font-family: 'FootlightMTLight';
  font-size: 15px;
}

.h1 {
  color: #012060;
}

@font-face {
  font-family: 'Monotype';
  src: url('../../../../public//Monotype\ Corsiva.ttf') format('truetype');
}

.monot {
  font-family: 'Monotype';
}

.abouttextname2 {
  font-family: 'Maiandra';
  font-size: 33px;
  color: white;
}

.chicc {
  color: #fff !important;
}

.fdd {
  font-family: 'maiandra' !important;
}
