
.bb:focus{
box-shadow: none;
}

   .flex-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  
  @media (min-width: 768px) {
    #templatemo_main_nav .navbar-nav {
      max-width: 450px;
    }
  }
  @media (max-width: 332px) {
    .logoVipar{
      width: 58px;
      height: 58px;
    }
  }
  @media (max-width: 341px) {
    .logoVipar{
      width: 58px;
      height: 58px;
    }
  }
  @media (min-width: 992px) {
    #templatemo_main_nav .navbar-nav {
      max-width: 550px;
    }
  }
  
  @media (min-width: 768px) {
    #templatemo_main_nav .navbar-nav {
      max-width: 450px;
    }
  }
  @media (min-width: 992px) {
    #templatemo_main_nav .navbar-nav {
      max-width: 550px;
    }
  }
  
  @media (min-width: 768px) {
    #templatemo_main_nav .navbar-nav {max-width: 450px;}
   }
  
  /* Large devices (desktops, 992px and up)*/
  @media (min-width: 992px) {
    #templatemo_main_nav .navbar-nav {max-width: 550px;}
    #template-mo-zay-hero-carousel .carousel-item {min-height: 30rem !important;}
    .product-wap .h3, .product-wap li, .product-wap i, .product-wap p {font-size: 18px !important;}
    .product-wap .product-color-dot {
      width: 12px;
      height: 12px;
    }
  }

  .active-link-vipar{
    color: rgb(155 212 20) !important;
  }
body{
  min-width: 320px;
}


#template-mo-zay-hero-carousel-2 .carousel-control-prev > i{
  color: black;
}

#template-mo-zay-hero-carousel-2 .carousel-control-next > i{
  color: black;
}