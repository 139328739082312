@keyframes pulse {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .25;
      transform: scale(.75);
    }
  }


#pulse .pulse{
    background-color: #1d2630;
    height: 100vh;
    justify-content: center;
    display: flex;
    align-items: center;
}
#pulse .pulse-container {
    width: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #pulse .pulse-bubble {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #3ff9dc;
  }
  
  #pulse .pulse-bubble-1 {
      animation: pulse .4s ease 0s infinite alternate;
  }
  #pulse .pulse-bubble-2 {
      animation: pulse .4s ease .2s infinite alternate;
  }
  #pulse .pulse-bubble-3 {
      animation: pulse .4s ease .4s infinite alternate;
  }

 