.cdd {
  font-size: 18px !important;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.nav-linkcart {
  /* padding: 0px 25px; */
  padding-right: 20px !important;
  padding-left: 20px !important;
  text-decoration: none !important;
  color: var(--navLink) !important;
  font-weight: 500;
  outline: none;
}

@media screen and (max-width: 570px) {
  .navbar .navbar-nav .nav-linkcart {
    /* padding: 0px 25px; */
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

@media screen and (max-width: 560px) {
  .nav-linkcart {
    font-size: 16px !important;
  }
  .ss {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 460px) {
  .nav-linkcart {
    font-size: 14px !important;
  }
  .ss {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 425px) {
  /* .fon {
      font-size: 15px !important;
    } */

  .navbar .navbar-nav .nav-linkcart {
    /* padding: 0px 25px; */
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}
@media screen and (max-width: 390px) {
  /* .logoVipar {
      width: 58px;
      height: 58px;
    }
    .fon {
      font-size: 14px !important;
    } */
  .nav-linkcart {
    font-size: 12px !important;
  }
  .ss {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 350px) {
  /* .fon {
      font-size: 15px !important;
    } */

  .navbar .navbar-nav .nav-linkcart {
    /* padding: 0px 25px; */
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
}

.navbar .navbar-nav .nav-linkcart:hover,
.navbar .navbar-nav .nav-linkcart.active {
  color: var(--dark);
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav {
    /* margin-top: 10px; */
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    background: #ffffff;
  }

  .navbar .navbar-nav .nav-linkcart {
    padding: 10px 0;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    visibility: hidden;
    top: 100%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
    transition: 0.5s;
    opacity: 0;
  }

  .navbar .nav-item:hover .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}
