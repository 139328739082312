.cardborder {
  border: 1px;
  border-color: #f8f9fa;
}

.h2 {
  font-family: 'FootlightMTLight';
  font-size: 30px !important;
  font-weight: 300;
}

.h3 {
  font-family: 'FootlightMTLight';
  font-size: 20px !important;
}
.h3 {
  orphans: 3;
  widows: 3;
}
.h3 {
  page-break-after: avoid;
}

.h3,
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.75rem;
  }
}

::after,
::before {
  box-sizing: border-box;
}

.btn-vio {
  background-color: #012060 !important;
  color: white;
}

.btn-vio:focus {
  background-color: gray !important;
  color: white;
}
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.fs {
  font-size: 1rem;
  text-transform: none;
}
.cardtext {
  font-family: 'FootlightMTLight';
  font-size: 1rem;
  font-weight: 400;
}

.fa {
  /* padding: 20px; */
  font-size: 30px;
  width: 50px;
  text-align: center;

  text-decoration: none;
  margin: 5px 2px;
}

.fa:hover {
  opacity: 0.7;
}

.facebook {
  border-radius: 15px;
  width: 50px;
  height: 50px;
  background: #3b5998;
  color: white;
}
.facebooknew {
  border-radius: 4px;
  width: 40px;
  height: 40px;
  background: #3b5998;
  color: white;
}

.twitter {
  border-radius: 15px;
  width: 50px;

  height: 50px;
  background: #55acee;
  color: white;
}

.linkedin {
  border-radius: 15px;

  width: 50px;

  height: 50px;
  background: #007bb5;
  color: white;
}

.whatsapp {
  border-radius: 15px;

  height: 50px;
  width: 50px;

  background: #4fce5d;
  color: white;
}
.assss {
  font-weight: 800 !important;
}
.as {
  text-decoration: underline !important;
  color: #0000ee !important;
}

.instagram {
  border-radius: 15px;
  width: 50px;

  height: 50px;
  color: aliceblue;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
.instagramnew {
  border-radius: 4px;
  width: 40px;

  height: 40px;
  color: aliceblue;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.dddddd {
  font-size: 20px;
  color: green;
}

.h4 {
  font-family: 'FootlightMTLight';
  font-size: 19px !important;
}

.h6,
h6 {
  font-size: 1rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.pppp {
  font-family: 'FootlightMTLight';
  line-height: 2;
}

.ftn:hover {
  color: #0d6efd !important;
}

.fassp {
  margin-top: -15vh;
}
