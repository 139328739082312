/*
Custom Css
*/

@font-face {
  /* font-family: 'Maiandra';
     src: url('/assets/font/Maiandra.ttf') format('truetype');
     font-family: 'FootlightMTLight';
     src: url('/assets/font/FootlightMTLight.ttf') format('truetype');
     font-family: 'gabriola';
     src: url('/assets/font/gabriola.ttf') format('truetype'); */
}

#invoiceid * {
  font-family: 'FootlightMTLight';
}
#invoiceid .editname {
  font-family: 'FootlightMTLight';
  font-weight: 300;
  font-size: 30px !important;
  width: 100%;
  border: 0;
}
#invoiceid .toperrrr {
  margin-top: -100px;
}

#invoiceid .headingbottom {
  font-family: 'FootlightMTLight';
  font-weight: 300;
  font-size: 30px !important;
  width: 100%;
  border: 0;
}

#invoiceid .subheadingbottom {
  font-family: 'FootlightMTLight';
  font-weight: 300;
  font-size: 20px !important;
  width: 100%;
  border: 0;
}

#invoiceid .editprice {
  font-family: 'FootlightMTLight';
  font-size: 22px !important;
  border: 0;
}

#invoiceid .editdis {
  font-family: 'FootlightMTLight';
  font-size: 18px !important;
  border: 0;
  font-weight: 300;
  width: 100%;
}

#invoiceid .emptycartheight {
  height: 75vh;
}

#invoiceid .cartempty {
  margin-top: 160px;
}

#invoiceid .card-blue {
  background-color: #492bc4;
}

#invoiceid .blueicon {
  color: #14083f;
  font-size: 25px;
}
#invoiceid .blueicon2 {
  color: white;
  font-size: 45px;
  font-family: 'Maiandra';
}

#invoiceid .fgufhi {
  font-size: 25px;
}
/* .tgjg{
    
    text-size-adjust: 50px;
} */

#invoiceid .footer {
  min-height: 100vh;
}
#invoiceid .subheadding {
  font-size: 23px;
  font-family: 'FootlightMTLight';
}
#invoiceid .linknew {
  text-decoration: none;
  color: black;
}

#invoiceid .p {
  /* font-family: 'Footlight+MT+Light'; */

  font-family: 'gabriola';
  font-size: 70px !important;
  font-weight: 300 !important;
}

#invoiceid #headernameofcarusel {
  font-family: 'gabriola';
}

#invoiceid .vipar {
  font-size: 45px;
  color: green;
}
#invoiceid.reg {
  color: green;
}
#invoiceid .viparfooter {
  font-size: 30px;
  color: #99d050;
}
#invoiceid .regfooter {
  color: green;
}

#invoiceid .cardheight {
  height: 150px;
}

#invoiceid .dubcbodyheight {
  height: 200px;
}

#invoiceid .modaltrans {
  background-color: #f8f9fa;
}

#invoiceid .discrpfvniv {
  font-size: 20px;
}
#invoiceid .pppp {
  font-family: 'FootlightMTLight';
  line-height: 2;
}

#invoiceid .abouttextcon {
  font-family: 'Maiandra';
  font-size: 45px;
  color: white;
}
#invoiceid .vipiargrrencolforabout {
  color: green;
  font-size: 70px;
}
#invoiceid .abouttextname2 {
  font-family: 'Maiandra';
  font-size: 33px;
  color: white;
}
#invoiceid .abouttextname {
  font-family: 'Maiandra';
  font-size: 25px;
  color: white;
}
#invoiceid .bgvipar {
  background-color: #012060;
}
#invoiceid .imagesize {
  /* margin-top: -100px; */
  height: 300px;
  width: 300px;
}
#invoiceid .pfont {
  font-family: 'Maiandra';
}

#invoiceid .sxgiugs {
  background-color: #0c0c0c;
}
#invoiceid .fggt {
  font-size: 48px;
  font-family: 'gabriola';
}

@media screen and (max-width: 540px) {
  #invoiceid .p {
    font-size: 40px !important;
  }
  #invoiceid .fggt {
    font-size: 35px;
  }
}
@media screen and (max-width: 445px) {
  #invoiceid .p {
    font-size: 26px !important;
  }
  #invoiceid .fggt {
    font-size: 25px;
  }
}
@media screen and (max-width: 1200px) {
  #invoiceid .vipiargrrencolforabout {
    font-size: 45px;
  }
  #invoiceid .abouttextcon {
    font-size: 28px;
  }
  #invoiceid .blueicon2 {
    font-size: 28px;
  }
  #invoiceid .abouttextname {
    font-size: 15px;
  }
  #invoiceid .abouttextname2 {
    font-size: 21px;
  }
  #invoiceid .vipar {
    font-size: 28px;
  }
  #invoiceid .imagesize {
    /* margin-top: px; */
    height: 250px;
    width: 250px;
  }
  #invoiceid .toperrrr {
    margin-top: -60px;
  }
}
@media screen and (max-width: 992px) {
  #invoiceid .imagesize {
    height: 200px;
    width: 200px;
  }
}
@media screen and (max-width: 767px) {
  #invoiceid .imagesize {
    /* margin-top: 3px; */
    /* padding: 0; */
    height: 200px;
    width: 200px;
  }
}

#invoiceid .cardimgwidth {
  width: 1300px;
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  #invoiceid .cardimgwidth {
    width: 522px;
  }
}
@media screen and (max-width: 577px) {
  #invoiceid .cardimgwidth {
    width: 450px;
  }
}

#invoiceid .linkcolor {
  text-decoration: green;
}

#invoiceid .blink_me {
  color: red;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
#invoiceid .printheight {
  /* height: 1122.519685px; */
  width: 793.7007874px;
  margin: auto;
  display: block;
}

#invoiceid table {
  display: block;
  border: 1px solid #ddd;
}

#invoiceid th {
  padding-left: 5px;
  font-size: 15px;
  border: 1px solid #ddd;
}

#invoiceid td {
  padding-left: 5px;
  font-size: 15px;
  border: 1px solid #ddd;
}
#invoiceid .cen {
  text-align: center;
}
#invoiceid .pt {
  padding: 0px 8px 0px 8px;
}
