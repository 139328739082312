.vipar-heading-shopping{
    font-size: 45px;
}
.vipar-cart-item{
border: none;
}
@media only screen and (max-width: 425px){
	/*Small smartphones [325px -> 425px]*/
    .vipar-heading-shopping{
        font-size: 25px;
    }
    .vipar-cart-item{
        border: 1px solid rgb(188, 185, 185);
        padding:10px 0px 10px 0px;
        border-radius: 3px;
    }
}