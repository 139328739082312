.card-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.fw-bold {
  font-weight: 700 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.text-success {
  color: #59ab6e !important;
}

.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-lighter-gray);
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.field__label {
  color: var(--color-gray);
  font-size: 20px;
  font-weight: 300;
  /* text-transform: uppercase; */
  margin-bottom: 0.25rem;
}

.field__input {
  padding: 0;
  margin: 0;
  border: 1px;
  outline: 0;
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
}
.field:focus-within {
  border-color: #000;
}

.fields {
  display: grid;
  grid-gap: 1rem;
}
.fields--2 {
  grid-template-columns: 1fr 1fr;
}
.fields--3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.show {
  display: block;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.fontss {
  font-size: 18px;
}

@media screen and (max-width:767px){
  .vipar-heading-shopping{
   font-size: 30px !important;
  }
}
