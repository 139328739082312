.vipar-top {
  position: fixed;
  height: -webkit-fill-available;
  z-index: 9999999999;
  left: 0;
  top: 0;
  background: #000000ab;
  overflow: hidden;
  display: flex;
}

.vipar-top div {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 14px;
  width: 100vw;
}

/* .vipar-top img {
  max-width: 540px;
} */

@media screen and (min-width: 450px) {
  .vipar-top img {
    max-width: 400px;
  }
}
/* @media screen and (max-width: 400px) {
  .vipar-top img {
    max-width: 350px;
  }
}
@media screen and (max-width: 300px) {
  .vipar-top img {
    max-width: 300px;
  }
} */
