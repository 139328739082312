.card-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.MainSubmitDiv {
  background: rgb(5, 28, 73);
  background: radial-gradient(
    circle,
    rgba(5, 28, 73, 1) 0%,
    rgba(13, 221, 86, 1) 28%,
    rgba(5, 28, 73, 1) 43%
  );

  height: 100vh;
}

.sabaricardforall {
  border-radius: 10px;
  color: rgb(35, 30, 30) !important;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.newSubmitButton {
  border-radius: 300px;
  color: white !important;
  background: rgb(5, 28, 73);

  border: none !important;
}

.btn-primary {
  color: white !important;
  background: rgb(5, 28, 73) !important;
}

.btn-outline-primary {
  border: 1px solid rgb(5, 28, 73) !important;
  color: rgb(5, 28, 73) !important;
  background: white !important;
}

.form-check-input:checked {
  background-color: rgb(5, 28, 73) !important;
  border-color: rgb(5, 28, 73) !important;
}
