.orderDetailsid .container-fluid {
  margin-top: 200px;
}

.orderDetailsid p {
  font-size: 14px;
  margin-bottom: 7px;
}

.orderDetailsid .small {
  letter-spacing: 0.5px !important;
}

/* .orderDetailsid .card-1 {
  box-shadow: 2px 2px 10px 0px rgb(190, 108, 170);
} */

/* .orderDetailsid hr {
  background-color: rgba(248, 248, 248, 0.667);
} */

.orderDetailsid .bold {
  font-weight: 500;
}

/* .orderDetailsid .change-color {
  color: #ab47bc !important;
} */

/* .orderDetailsid .card-2 {
  box-shadow: 1px 1px 3px 0px rgb(112, 115, 139);
} */

/* .orderDetailsid .fa-circle.active {
  font-size: 8px;
  color: #ab47bc;
} */

.orderDetailsid .fa-circle {
  font-size: 8px;
  /* color: #aaa; */
}

.orderDetailsid .rounded {
  border-radius: 2.25rem !important;
}

/* .orderDetailsid .progress-bar {
  background-color: #ab47bc !important;
} */

.orderDetailsid .progress {
  height: 5px !important;
  margin-bottom: 0;
}

.orderDetailsid .invoice {
  position: relative;
  top: -70px;
}

.orderDetailsid .Glasses {
  position: relative;
  top: -12px !important;
}

/* .orderDetailsid .card-footer {
  background-color: #ab47bc;
  color: #fff;
} */

.orderDetailsid h2 {
  /* color: rgb(78, 0, 92); */
  letter-spacing: 2px !important;
}

.orderDetailsid .display-3 {
  font-weight: 500 !important;
}

@media (max-width: 479px) {
  .orderDetailsid .invoice {
    position: relative;
    top: 7px;
  }

  /* .orderDetailsid .border-line {
    border-right: 0px solid rgb(226, 206, 226) !important;
  } */
}

@media (max-width: 700px) {
  .orderDetailsid h2 {
    /* color: rgb(78, 0, 92); */
    font-size: 17px;
  }

  .orderDetailsid .display-3 {
    font-size: 28px;
    font-weight: 500 !important;
  }
}

.orderDetailsid .card-footer small {
  letter-spacing: 7px !important;
  font-size: 12px;
}
/* 
.orderDetailsid .border-line {
  border-right: 1px solid rgb(226, 206, 226);
} */

.btnoutt {
  border: 1px solid #012060 !important;
  background-color: white !important;
  color: #012060 !important;

  cursor: pointer;
}
