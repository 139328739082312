.ftnn {
  font-family: 'FootlightMTLight';
  font-size: 25px !important;
  font-weight: 300;
}

.subheadding {
  font-size: 20px;
  font-weight: 300;
  font-family: 'FootlightMTLight';
}

.fn {
  font-family: 'FootlightMTLight';
  font-size: 19px !important;
  font-weight: 100;
}
.font25px {
  font-size: 25px !important;
}

.fa {
  /* padding: 20px; */
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}

.fa:hover {
  opacity: 0.7;
}

.facebook {
  border-radius: 20px;
  height: 75px;
  background: #3b5998;
  color: white;
}

.twitter {
  height: 75px;

  border-radius: 20px;
  background: #55acee;
  color: white;
}

.linkedin {
  height: 75px;

  border-radius: 20px;
  background: #007bb5;
  color: white;
}

.whatsapp {
  height: 75px;

  border-radius: 20px;
  background: #4fce5d;
  color: white;
}

.instagram {
  height: 75px;

  border-radius: 20px;
  color: aliceblue;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.pppp {
  line-height: 2;
}

.sub {
  font-family: 'FootlightMTLight';
  font-weight: 500;
  font-size: 20px;
}

.blink_me {
  animation: blinker 0.7s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.cardimgwidth {
  width: 1300px;
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .cardimgwidth {
    width: 522px;
  }
}
@media screen and (max-width: 577px) {
  .cardimgwidth {
    width: 450px;
  }
}
