.text-gray-500 {
    --text-opacity: 1;
    /* color: #a0aec0; */
    color: rgba(160, 174, 192, var(--text-opacity));
    font-size: 18px;
  }
  
  .tracking-wider {
    letter-spacing: 0.05em;
  }
  