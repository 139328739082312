@media only screen and (max-width: 425px) {
  /* Small smartphones [325px -> 425px]
  .media {
    color: rosybrown;
  } */
  #foooo {
    min-height: calc(100vh - 85px - 242px);
  }

  #fooo {
    min-height: calc(100vh - 63px - 218px);
  }

  #foo {
    min-height: calc(100vh - 63px - 122px);
  }
  #fo {
    min-height: calc(100vh - 240px - 122px);
  }
  #fot {
    min-height: calc(100vh - 190px - 122px);
  }
}
@media only screen and (min-width: 425px) {
  /* .media {
    color: green;
  } */
  #foooo {
    min-height: calc(100vh - 89px - 257px);
  }
  #fooo {
    min-height: calc(100vh - 63px - 260px);
  }
  #foo {
    min-height: calc(100vh - 63px - 122px);
  }
  #fo {
    min-height: calc(100vh - 206px - 122px);
  }
  #fot {
    min-height: calc(100vh - 190px - 122px);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
  .media {
    color: greenyellow;
  }
} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {
  .media {
    color: yellow;
  }
} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {
  .media {
    color: orange;
  }
} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {
  .media {
    color: blue;
  }
} */

#blink {
  animation: blink 0.3s step-start infinite;
}

@keyframes blink {
  50% {
    border-color: white;
  }
}

#fooo {
  min-height: calc(100vh - 122px - 122px);
}

.pd {
  padding-top: 4px !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .sz {
    font-size: 13px !important;
  }
}

.newnav {
  padding: 0px 25px;
  padding-right: 20px !important;

  color: #555555;
  font-weight: 500;
  outline: none;
}

#navnew .nav-link {
  padding-left: 0px !important;
}

.blinkcom {
  animation: blinker 0.7s linear infinite;
}

@keyframes blinkcom {
  50% {
    opacity: 0;
  }
}
