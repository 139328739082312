

.monot {
  font-family: 'Monotype';
}
.dddddd {
  font-size: 20px;
  color: green;
}

.reggg {
  color: green;
}
