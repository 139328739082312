/********** Template CSS **********/
:root {
  --primary: #eb1616;
  --secondary: #191c24;
  --light: #6c7293;
  --dark: #000000;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  transition: 0.5s;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}

/*** Layout ***/
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  height: 100vh;
  overflow-y: auto;
  background: var(--dark);
  transition: 0.5s;
  z-index: 999;
}

.content {
  margin-left: 250px;
  min-height: 100vh;
  background: var(--dark);
  transition: 0.5s;
}

@media (min-width: 992px) {
  .sidebar {
    margin-left: 0;
  }

  .sidebar.open {
    margin-left: -250px;
  }

  .content {
    width: calc(100% - 250px);
  }

  .content.open {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 991.98px) {
  .sidebar {
    margin-left: -250px;
  }

  .sidebar.open {
    margin-left: 0;
  }

  .content {
    width: 100%;
    margin-left: 0;
  }
}

/*** Navbar ***/
.sidebar .navbar .navbar-nav .nav-link {
  padding: 7px 20px;
  color: var(--light);
  font-weight: 500;
  border-left: 3px solid var(--secondary);
  border-radius: 0 30px 30px 0;
  outline: none;
}



.sidebar .navbar .navbar-nav .nav-link i {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}



.sidebar .navbar .dropdown-toggle::after {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  transition: 0.5s;
}

.sidebar .navbar .dropdown-toggle[aria-expanded='true']::after {
  transform: rotate(-180deg);
}

.sidebar .navbar .dropdown-item {
  padding-left: 25px;
  border-radius: 0 30px 30px 0;
  color: var(--light);
}

.sidebar .navbar .dropdown-item:hover,
.sidebar .navbar .dropdown-item.active {
  background: var(--dark);
}

.content .navbar .navbar-nav .nav-link {
  margin-left: 25px;
  padding: 12px 0;
  color: var(--dark);
  outline: none;
}

.content .navbar .navbar-nav .nav-link:hover,
.content .navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.content .navbar .sidebar-toggler,
.content .navbar .navbar-nav .nav-link i {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.content .navbar .dropdown-item {
  color: var(--light);
}

.content .navbar .dropdown-item:hover,
.content .navbar .dropdown-item.active {
  background: var(--dark);
}

.content .navbar .dropdown-toggle::after {
  margin-left: 6px;
  vertical-align: middle;
  border: none;
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  transition: 0.5s;
}

.content .navbar .dropdown-toggle[aria-expanded='true']::after {
  transform: rotate(-180deg);
}

@media (max-width: 575.98px) {
  .content .navbar .navbar-nav .nav-link {
    margin-left: 15px;
  }
}

/*** Date Picker ***/
.bootstrap-datetimepicker-widget.bottom {
  top: auto !important;
}

.bootstrap-datetimepicker-widget .table * {
  border-bottom-width: 0px;
}

.bootstrap-datetimepicker-widget .table th {
  font-weight: 500;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 10px;
  border-radius: 2px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background: var(--primary);
}

.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: var(--primary);
}

/*** Testimonial ***/
.progress .progress-bar {
  width: 0px;
  transition: 2s;
}

/*** Testimonial ***/
.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 5px solid var(--primary);
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--dark);
  border-color: var(--primary);
}

.fs {
  font-size: 15px;
}

.viparr:hover {
  background-color: #000000 !important;
}

.switchContainer {
  position: relative;
  width: 80px;
  height: 30px;
  background-color: rgb(0, 0, 0);
  border-radius: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  padding: 10px;
  border: 1px solid white;
}

.switchButton {
  position: absolute;
  top: -5px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  left: -2px;
  transition: all 0.2s linear;
  background-color: #6c7293;

}

.addedrtr {
  left: calc(100% - 38px);
}

.light {
  background-color: white;
}

.dark {
  background-color: black;
}